import {Button as MantineButton} from "@mantine/core";
import React, {forwardRef} from "react";

import styles from "./Button.module.css";

/**
 * @description Wrapper custom component for Mantine Button
 * @param size - Size of the button | default: xs | xs | sm | md | lg | xl | xxl
 * @param sizeVariant - Size variant | default: false | false | 22 | 24 | 26 | 28 | 30 | 32 | 34 | 36
 */
const Button = forwardRef(({bgVariant = 'primary', sizeVariant = 0, size = 'xs', ...props}, ref) => {
    return (
        <MantineButton
            classNames={styles}
            ref={ref}
            data-bg={bgVariant}
            size-variant={sizeVariant !== 0 ? sizeVariant : null}
            size={size}
            {...props}
        />
    );
});

export default Button;
