import {useCallback, useEffect, useState} from 'react';

import en from '../locales/en.json';
import es from '../locales/es.json';
import pt from '../locales/pt.json';

import {SUPPORTED_LANGUAGES} from './constants';

export const useTranslation = (language) => {
    const [translations, setTranslations] = useState({});

    useEffect(() => {
        const getTranslations = () => {
            // Simulate async loading of locales
            let translatedTexts;

            switch (language) {
                case SUPPORTED_LANGUAGES.SPANISH:
                    translatedTexts = es;
                    break;
                case SUPPORTED_LANGUAGES.ENGLISH:
                    translatedTexts = en;
                    break;
                case SUPPORTED_LANGUAGES.PORTUGUESE:
                    translatedTexts = pt;
                    break;
                default:
                    translatedTexts = es;
                    break;
            }
            setTranslations(translatedTexts);
        };

        getTranslations();
    }, [language]);

    const t = useCallback((key) => {
        return translations[key] || key;
    }, [translations]);

    return {t};
};