import {rem, useMantineColorScheme} from "@mantine/core";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {lazy, Suspense, useEffect, useState} from "react";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";


import {LanguageProvider} from "./contexts/LanguageContext";
import {LoadingProvider} from "./contexts/LoadingContext";
import {NotificationsProvider} from "./contexts/NotificationContext";
import {OperationFiltersProvider} from "./contexts/OperationFiltersContext";
import {PERMISSION, ROUTES} from "./util/constants";
import {getAuth} from "./util/functions";
import {STORAGE} from "./util/localStorage";

/* auth */
const Header = lazy(() => import("./components/header/Header"));
const Login = lazy(() => import("./pages/login/Login"));
const Home = lazy(() => import("./pages/home/Home"));

/* analytics*/
const GeneralDashboard = lazy(() => import("./pages/analytics/dashboards/GeneralDashboard"))
const CommercialDashboard = lazy(() => import("./pages/analytics/dashboards/CommercialDashboard"))
const AdministrativeDashboard = lazy(() => import("./pages/analytics/dashboards/AdministrativeDashboard"))
const Reports = lazy(() => import("./pages/analytics/reports/base/Reports"));

/* administration */
const Agentes = lazy(() => import("./pages/administration/providers/Providers"));
const ChargeCodes = lazy(() => import("./pages/administration/charge_codes/ChargeCodes"));
const Notes = lazy(() => import("./pages/administration/notes/Notes"));
const Organizaciones = lazy(() => import("./pages/administration/organizations/Organizaciones"));
const Usuarios = lazy(() => import("./pages/administration/users/Users"));

/* quotations */
const FinalizeQuoteModal = lazy(() => import("./pages/quotations/my_quotations/FinalizeQuoteModal"));
const MisCotizaciones = lazy(() => import("./pages/quotations/my_quotations/MyQuotations"));
const Quotations = lazy(() => import("./pages/quotations/Quotations"));
const QuotationAgents = lazy(() => import("./pages/quotations/quotation_agents/QuotationAgents"))

/* pricing */
const QuotationRules = lazy(() => import("./pages/pricing/QuotationRules/QuotationRules"));
const Rates = lazy(() => import("./pages/pricing/Rates/Rates"));

/* operations */
const ExpressOperationsList = lazy(() => import("./pages/operations/express/ExpressOperationsList"));
const OperationsHub = lazy(() => import("./pages/operations/general/OperationsHub"));

/* drafts */
const Drafts = lazy(() => import("./pages/drafts/Drafts"));

/* third-party */
const ThirdPartyNewCost = lazy(() => import("./pages/thirdParty/ThirdPartyNewCost"));

/* base components */
const LoadingSpinner = lazy(() => import("./components/base/spinner/LoadingSpinner"));

const queryClient = new QueryClient()

function App() {
    const [loggedIn, setLoggedIn] = useState(getAuth()[0]);
    // Determine if it's the third-party route
    const isThirdPartyRoute = window.location.pathname.startsWith('/third-party');

    useEffect(() => {
        if (!getAuth()[0]) {
            setLoggedIn(false);
        }
    }, [localStorage.getItem(STORAGE.TOKEN)]);

    const {colorScheme} = useMantineColorScheme();
    document.body.setAttribute('data-theme', colorScheme);

    return (
        <QueryClientProvider client={queryClient}>
            <LanguageProvider>
                <LoadingProvider>
                    <Router>
                        <NotificationsProvider>
                            <Suspense fallback={<LoadingSpinner/>}>
                                {loggedIn && <Header setLoggedIn={setLoggedIn}/>}
                                <div style={{paddingTop: (loggedIn && !isThirdPartyRoute) ? rem(62.5) : '0px'}}>
                                    <Routes>
                                        <Route path={ROUTES.LOGIN.path} element={loggedIn ? <Navigate to={ROUTES.HOME.path} replace/> :
                                            <Login setLoggedIn={setLoggedIn}/>}/>
                                        <Route path={ROUTES.HOME.path} element={
                                            <RequireAuth>
                                                <Home/>
                                            </RequireAuth>
                                        }/>
                                        <Route path={ROUTES.GENERAL_DASHBOARD.path} element={
                                            <RequireAuth permission={ROUTES.GENERAL_DASHBOARD.permission}>
                                                <GeneralDashboard/>
                                            </RequireAuth>
                                        }/>
                                        {/*<Route path={ROUTES.COMMERCIAL_DASHBOARD.path} element={
                                        <RequireAuth permission={ROUTES.COMMERCIAL_DASHBOARD.permission}>
                                            <CommercialDashboard/>
                                        </RequireAuth>
                                    }/>
                                    <Route path={ROUTES.ADMINISTRATIVE_DASHBOARD.path} element={
                                        <RequireAuth permission={ROUTES.ADMINISTRATIVE_DASHBOARD.permission}>
                                            <AdministrativeDashboard/>
                                        </RequireAuth>
                                    }/>*/}
                                        <Route path={ROUTES.REPORTS.path} element={
                                            <RequireAuth permission={ROUTES.REPORTS.permission}>
                                                <Reports/>
                                            </RequireAuth>
                                        }/>
                                        <Route path={`${ROUTES.MY_QUOTATIONS.path}/:ref?`} element={
                                            <RequireAuth permission={ROUTES.MY_QUOTATIONS.permission}>
                                                <MisCotizaciones util={ROUTES.MY_QUOTATIONS.path}/>
                                            </RequireAuth>
                                        }/>
                                        <Route path="/mis-cotizaciones/:ref?" element={
                                            <RequireAuth permission={PERMISSION.COMERCIAL}>
                                                <MisCotizaciones/>
                                            </RequireAuth>
                                        }/>
                                        <Route path={`${ROUTES.MY_QUOTATIONS_HISTORY.path}/:ref?`} element={
                                            <RequireAuth permission={ROUTES.MY_QUOTATIONS_HISTORY.permission}>
                                                <MisCotizaciones util={ROUTES.MY_QUOTATIONS_HISTORY.path} isHistory={true}/>
                                            </RequireAuth>
                                        }/>
                                        <Route path={`${ROUTES.FINALIZE_QUOTE.path}/:ref?`} element={
                                            <RequireAuth permission={ROUTES.FINALIZE_QUOTE.permission}>
                                                <FinalizeQuoteModal/>
                                            </RequireAuth>
                                        }/>
                                        <Route path={`${ROUTES.ASSIGNED.path}/:ref?`} element={
                                            <RequireAuth permission={ROUTES.ASSIGNED.permission}>
                                                <MisCotizaciones util={ROUTES.ASSIGNED.path}/>
                                            </RequireAuth>
                                        }/>
                                        <Route path={`${ROUTES.QUOTATIONS_MANAGER.path}/:ref?`} element={
                                            <RequireAuth permission={ROUTES.QUOTATIONS_MANAGER.permission}>
                                                <Quotations util={ROUTES.QUOTATIONS_MANAGER.path}/>
                                            </RequireAuth>
                                        }/>
                                        <Route path={`${ROUTES.PRICING_NEW_REQUESTS.path}/:ref?`} element={
                                            <RequireAuth permission={ROUTES.PRICING_NEW_REQUESTS.permission}>
                                                <Quotations util={ROUTES.PRICING_NEW_REQUESTS.path}/>
                                            </RequireAuth>
                                        }/>
                                        <Route path={`${ROUTES.PRICING_IN_PROGRESS.path}/:ref?`} element={
                                            <RequireAuth permission={ROUTES.PRICING_IN_PROGRESS.permission}>
                                                <Quotations util={ROUTES.PRICING_IN_PROGRESS.path}/>
                                            </RequireAuth>
                                        }/>
                                        <Route path={`${ROUTES.PRICING_QUOTATIONS.path}/:ref?`} element={
                                            <RequireAuth permission={ROUTES.PRICING_QUOTATIONS.permission}>
                                                <Quotations util={ROUTES.PRICING_QUOTATIONS.path}/>
                                            </RequireAuth>
                                        }/>
                                        <Route path={`${ROUTES.PRICING_QUOTE.path}/:ref?`} element={
                                            <RequireAuth permission={ROUTES.PRICING_QUOTE.permission}>
                                                <QuotationAgents/>
                                            </RequireAuth>
                                        }/>
                                        <Route path={ROUTES.ORGANIZATIONS.path} element={
                                            <RequireAuth permission={ROUTES.ORGANIZATIONS.permission}>
                                                <Organizaciones/>
                                            </RequireAuth>
                                        }/>
                                        <Route path={ROUTES.PROVIDERS.path} element={
                                            <RequireAuth permission={ROUTES.PROVIDERS.permission}>
                                                <Agentes/>
                                            </RequireAuth>
                                        }/>
                                        <Route path={ROUTES.CHARGE_CODES.path} element={
                                            <RequireAuth permission={ROUTES.CHARGE_CODES.permission}>
                                                <ChargeCodes/>
                                            </RequireAuth>
                                        }/>
                                        <Route path={ROUTES.USERS.path} element={
                                            <RequireAuth permission={ROUTES.USERS.permission}>
                                                <Usuarios/>
                                            </RequireAuth>
                                        }/>
                                        <Route path={`${ROUTES.OPERATIONS_GENERAL.path}/:reference?`} element={
                                            <RequireAuth permission={ROUTES.OPERATIONS_GENERAL.permission}>
                                                <OperationFiltersProvider>
                                                    <OperationsHub/>
                                                </OperationFiltersProvider>
                                            </RequireAuth>
                                        }/>
                                        <Route path={`${ROUTES.OPERATIONS_EXPRESS.path}/:status`} element={
                                            <RequireAuth permission={ROUTES.OPERATIONS_EXPRESS.permission}>
                                                <ExpressOperationsList/>
                                            </RequireAuth>
                                        }/>
                                        <Route path={`${ROUTES.PRICING_RATES.path}/:id?`} element={
                                            <RequireAuth permission={ROUTES.PRICING_RATES.permission}>
                                                <Rates util={ROUTES.PRICING_RATES.path}/>
                                            </RequireAuth>
                                        }/>
                                        <Route path={ROUTES.PRICING_QUOTATION_RULES.path} element={
                                            <RequireAuth permission={ROUTES.PRICING_QUOTATION_RULES.permission}>
                                                <QuotationRules/>
                                            </RequireAuth>
                                        }/>
                                        <Route path={ROUTES.DRAFTS.path} element={
                                            <RequireAuth permission={ROUTES.DRAFTS.permission}>
                                                <Drafts/>
                                            </RequireAuth>
                                        }/>
                                        <Route path={ROUTES.NOTES.path} element={
                                            <RequireAuth permission={ROUTES.NOTES.permission}>
                                                <Notes/>
                                            </RequireAuth>
                                        }/>
                                        <Route path={`${ROUTES.THIRD_PARTY.path}/:encodedJwt`} element={<ThirdPartyNewCost/>}/>
                                        <Route path="/*" element={<Navigate to={ROUTES.HOME.path} replace/>}/>
                                    </Routes>
                                </div>
                            </Suspense>
                        </NotificationsProvider>
                    </Router>
                </LoadingProvider>
            </LanguageProvider>
        </QueryClientProvider>
    )
}

export default App;

const RequireAuth = ({children, permission}) => {
    const loggedIn = getAuth()[0];

    if (!loggedIn) {
        return <Navigate to={ROUTES.LOGIN.path} replace/>;
    }
    if (permission && !getAuth(permission)[0]) {
        return <Navigate to={ROUTES.HOME.path} replace/>;
    }

    return children;
};