import React from "react";
import "./spinner.css";
import {Loader} from "@mantine/core";

export default function LoadingSpinner() {
    return (
        <div className="spinner-container">
            <Loader data-c={"primary"} type={"dots"}/>
        </div>
    );
}