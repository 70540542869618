import {useIsFirstRender} from '@mantine/hooks';
import _ from 'lodash';
import {createContext, useContext, useEffect, useReducer} from 'react';

import {OPERATION_FILTERS_INITIAL_STATE, STORAGE_PREFIXES} from '../util/constants';
import {STORAGE} from '../util/localStorage';
import {TRANSLATION_KEYS} from '../util/translation-keys';

const OperationFiltersContext = createContext();

function OperationFiltersProvider({children}) {
    const isFirstRender = useIsFirstRender();
    const LOCAL_STORAGE_PREFIX = STORAGE_PREFIXES.operationFilters;
    const init = () => {
        const storedEtdData = STORAGE.get(`${LOCAL_STORAGE_PREFIX}-etd`);
        const storedEtaData = STORAGE.get(`${LOCAL_STORAGE_PREFIX}-eta`);

        return {
            ...OPERATION_FILTERS_INITIAL_STATE,
            commercials: {
                ...OPERATION_FILTERS_INITIAL_STATE.commercials,
                selectedValue: STORAGE.get(`${LOCAL_STORAGE_PREFIX}-commercials`) || OPERATION_FILTERS_INITIAL_STATE.commercials.selectedValue
            },
            operatives: {
                ...OPERATION_FILTERS_INITIAL_STATE.operatives,
                selectedValue: STORAGE.get(`${LOCAL_STORAGE_PREFIX}-operatives`) || OPERATION_FILTERS_INITIAL_STATE.operatives.selectedValue
            },
            clientName: {
                ...OPERATION_FILTERS_INITIAL_STATE.clientName,
                selectedValue: STORAGE.get(`${LOCAL_STORAGE_PREFIX}-clientName`) || OPERATION_FILTERS_INITIAL_STATE.clientName.selectedValue
            },
            transportModes: {
                ...OPERATION_FILTERS_INITIAL_STATE.transportModes,
                selectedValue: STORAGE.get(`${LOCAL_STORAGE_PREFIX}-transportModes`) || OPERATION_FILTERS_INITIAL_STATE.transportModes.selectedValue
            },
            containerModes: {
                ...OPERATION_FILTERS_INITIAL_STATE.containerModes,
                selectedValue: STORAGE.get(`${LOCAL_STORAGE_PREFIX}-containerModes`) || OPERATION_FILTERS_INITIAL_STATE.containerModes.selectedValue
            },
            origins: {
                ...OPERATION_FILTERS_INITIAL_STATE.origins,
                selectedValue: STORAGE.get(`${LOCAL_STORAGE_PREFIX}-origins`) || OPERATION_FILTERS_INITIAL_STATE.origins.selectedValue
            },
            destinations: {
                ...OPERATION_FILTERS_INITIAL_STATE.destinations,
                selectedValue: STORAGE.get(`${LOCAL_STORAGE_PREFIX}-destinations`) || OPERATION_FILTERS_INITIAL_STATE.destinations.selectedValue
            },
            etd: {
                ...OPERATION_FILTERS_INITIAL_STATE.etd,
                selectedValue: {
                    group: STORAGE.get(`${LOCAL_STORAGE_PREFIX}-etd`).group || OPERATION_FILTERS_INITIAL_STATE.etd.selectedValue.group,
                    dates: [
                        storedEtdData && storedEtdData?.dates[0]
                            ? new Date(storedEtdData.dates[0])
                            : OPERATION_FILTERS_INITIAL_STATE.etd.selectedValue.dates[0],
                        storedEtdData && storedEtdData.dates[1]
                            ? new Date(storedEtdData.dates[1])
                            : OPERATION_FILTERS_INITIAL_STATE.etd.selectedValue.dates[1],
                    ]
                }
            },
            eta: {
                ...OPERATION_FILTERS_INITIAL_STATE.eta,
                selectedValue: {
                    group: STORAGE.get(`${LOCAL_STORAGE_PREFIX}-eta`).group || OPERATION_FILTERS_INITIAL_STATE.eta.selectedValue.group,
                    dates: [
                        storedEtaData && storedEtaData.dates[0]
                            ? new Date(storedEtaData.dates[0])
                            : OPERATION_FILTERS_INITIAL_STATE.eta.selectedValue.dates[0],
                        storedEtaData && storedEtaData.dates[1]
                            ? new Date(storedEtaData.dates[1])
                            : OPERATION_FILTERS_INITIAL_STATE.eta.selectedValue.dates[1],
                    ]
                }
            },
            status: {
                ...OPERATION_FILTERS_INITIAL_STATE.status,
                selectedValue: STORAGE.get(`${LOCAL_STORAGE_PREFIX}-status`) || OPERATION_FILTERS_INITIAL_STATE.status.selectedValue
            },
            houseNumber: {
                ...OPERATION_FILTERS_INITIAL_STATE.houseNumber,
                selectedValue: STORAGE.get(`${LOCAL_STORAGE_PREFIX}-houseNumber`) || OPERATION_FILTERS_INITIAL_STATE.houseNumber.selectedValue
            },
            masterNumber: {
                ...OPERATION_FILTERS_INITIAL_STATE.masterNumber,
                selectedValue: STORAGE.get(`${LOCAL_STORAGE_PREFIX}-masterNumber`) || OPERATION_FILTERS_INITIAL_STATE.masterNumber.selectedValue
            },
            shipmentKey: {
                ...OPERATION_FILTERS_INITIAL_STATE.shipmentKey,
                selectedValue: STORAGE.get(`${LOCAL_STORAGE_PREFIX}-shipmentKey`) || OPERATION_FILTERS_INITIAL_STATE.shipmentKey.selectedValue
            },
            containerNumber: {
                ...OPERATION_FILTERS_INITIAL_STATE.containerNumber,
                selectedValue: STORAGE.get(`${LOCAL_STORAGE_PREFIX}-containerNumber`) || OPERATION_FILTERS_INITIAL_STATE.containerNumber.selectedValue
            },
            quoteNumber: {
                ...OPERATION_FILTERS_INITIAL_STATE.quoteNumber,
                selectedValue: STORAGE.get(`${LOCAL_STORAGE_PREFIX}-quoteNumber`) || OPERATION_FILTERS_INITIAL_STATE.quoteNumber.selectedValue
            },
        };
    };
    const [operationFilters, operationFiltersDispatch] = useReducer(operationFiltersReducer, null, init);

    useEffect(() => {
        if (!isFirstRender && operationFilters) {
            const debouncedFn = _.debounce(() => {
                Object.entries(operationFilters).map(([key, value]) => {
                    STORAGE.set(
                        `${LOCAL_STORAGE_PREFIX}-${key}`,
                        value.selectedValue === undefined ? null : value.selectedValue
                    );
                });
            }, 500, {trailing: true, leading: false});

            debouncedFn();
            /* if the effect is called again or the component is dismounted, cancel the planed function call */
            return () => debouncedFn.cancel();
        }
    }, [operationFilters]);

    return (
        <OperationFiltersContext.Provider
            value={{
                operationFilters,
                operationFiltersDispatch
            }}>
            {children}
        </OperationFiltersContext.Provider>
    );
}

function useOperationFilters() {
    return useContext(OperationFiltersContext);
}

function operationFiltersReducer(state, action, t = null) {
    switch (action.type) {
        case OPERATION_FILTERS_REDUCER.GET_OPERATIVES:
            return {
                ...state,
                operatives: {
                    ...state.operatives,
                    values: action.value.map((operative) => ({
                        value: operative.code,
                        label: operative.name
                    })),
                },
            };
        case OPERATION_FILTERS_REDUCER.GET_COMMERCIALS:
            return {
                ...state,
                commercials: {
                    ...state.commercials,
                    values: action.value.map((commercial) => ({
                        value: commercial.code,
                        label: commercial.name
                    })),
                },

            };
        case OPERATION_FILTERS_REDUCER.GET_STATUS:
            return {
                ...state,
                status: {
                    ...state.status,
                    values: action.value.map((status) => ({
                        value: status.code,
                        label: action.t(TRANSLATION_KEYS[`STATUS_${status.code}`])
                    })),
                },

            };
        case OPERATION_FILTERS_REDUCER.SET_OPERATIVES:
        case OPERATION_FILTERS_REDUCER.SET_COMMERCIALS:
        case OPERATION_FILTERS_REDUCER.SET_CLIENT_NAME:
        case OPERATION_FILTERS_REDUCER.SET_TRANSPORT_MODES:
        case OPERATION_FILTERS_REDUCER.SET_CONTAINER_MODES:
        case OPERATION_FILTERS_REDUCER.SET_ORIGINS:
        case OPERATION_FILTERS_REDUCER.SET_DESTINATIONS:
        case OPERATION_FILTERS_REDUCER.SET_ETD:
        case OPERATION_FILTERS_REDUCER.SET_ETA:
        case OPERATION_FILTERS_REDUCER.SET_STATUS:
        case OPERATION_FILTERS_REDUCER.SET_HOUSE_NUMBER:
        case OPERATION_FILTERS_REDUCER.SET_MASTER_NUMBER:
        case OPERATION_FILTERS_REDUCER.SET_SHIPMENT_KEY:
        case OPERATION_FILTERS_REDUCER.SET_CONTAINER_NUMBER:
        case OPERATION_FILTERS_REDUCER.SET_QUOTE_NUMBER:
            return {
                ...state,
                [action.type]: {
                    ...state[action.type],
                    selectedValue: action.value,
                },
            };
        case OPERATION_FILTERS_REDUCER.CLEAR:
            return {
                ...state,
                operatives: {
                    ...state.operatives,
                    selectedValue: OPERATION_FILTERS_INITIAL_STATE.operatives.selectedValue,
                },
                commercials: {
                    ...state.commercials,
                    selectedValue: OPERATION_FILTERS_INITIAL_STATE.commercials.selectedValue,
                },
                clientName: {
                    ...state.clientName,
                    selectedValue: OPERATION_FILTERS_INITIAL_STATE.clientName.selectedValue,
                },
                transportModes: {
                    ...state.transportModes,
                    selectedValue: OPERATION_FILTERS_INITIAL_STATE.transportModes.selectedValue,
                },
                containerModes: {
                    ...state.containerModes,
                    selectedValue: OPERATION_FILTERS_INITIAL_STATE.containerModes.selectedValue,
                },
                origins: {
                    ...state.origins,
                    selectedValue: OPERATION_FILTERS_INITIAL_STATE.origins.selectedValue,
                },
                destinations: {
                    ...state.destinations,
                    selectedValue: OPERATION_FILTERS_INITIAL_STATE.destinations.selectedValue,
                },
                etd: {
                    ...state.etd,
                    selectedValue: OPERATION_FILTERS_INITIAL_STATE.etd.selectedValue,
                },
                eta: {
                    ...state.eta,
                    selectedValue: OPERATION_FILTERS_INITIAL_STATE.eta.selectedValue,
                },
                status: {
                    ...state.status,
                    selectedValue: OPERATION_FILTERS_INITIAL_STATE.status.selectedValue,
                },
                houseNumber: {
                    ...state.houseNumber,
                    selectedValue: OPERATION_FILTERS_INITIAL_STATE.houseNumber.selectedValue,
                },
                masterNumber: {
                    ...state.masterNumber,
                    selectedValue: OPERATION_FILTERS_INITIAL_STATE.masterNumber.selectedValue,
                },
                shipmentKey: {
                    ...state.shipmentKey,
                    selectedValue: OPERATION_FILTERS_INITIAL_STATE.shipmentKey.selectedValue,
                },
                containerNumber: {
                    ...state.containerNumber,
                    selectedValue: OPERATION_FILTERS_INITIAL_STATE.containerNumber.selectedValue,
                },
                quoteNumber: {
                    ...state.quoteNumber,
                    selectedValue: OPERATION_FILTERS_INITIAL_STATE.quoteNumber.selectedValue,
                },
            };
        default:
            return state;
    }
}

export const OPERATION_FILTERS_REDUCER = {
    GET_OPERATIVES: 'getOperatives',
    GET_COMMERCIALS: 'getCommercials',
    GET_STATUS: 'getStatus',
    SET_OPERATIVES: 'operatives',
    SET_COMMERCIALS: 'commercials',
    SET_CLIENT_NAME: 'clientName',
    SET_TRANSPORT_MODES: 'transportModes',
    SET_CONTAINER_MODES: 'containerModes',
    SET_ORIGINS: 'origins',
    SET_DESTINATIONS: 'destinations',
    SET_ETD: 'etd',
    SET_ETA: 'eta',
    SET_STATUS: 'status',
    SET_HOUSE_NUMBER: 'houseNumber',
    SET_MASTER_NUMBER: 'masterNumber',
    SET_SHIPMENT_KEY: 'shipmentKey',
    SET_CONTAINER_NUMBER: 'containerNumber',
    SET_QUOTE_NUMBER: 'quoteNumber',
    CLEAR: 'clear'
};

export {OperationFiltersProvider, useOperationFilters};